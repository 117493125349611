
import { api } from "@/base/utils/request";


export const getDetail = (data) => {
  return api({
    url: '/admin/venue/reserve/detail',
    method: 'post',
    data
  })
}

export const uncheck = data => {
  return api({
    url: "/admin/venue/reserve/uncheck",
    method: "post",
    data
  });
}
export const check = data => {
  return api({
    url: "/admin/venue/reserve/check",
      method: "post",
    data
  });
}
