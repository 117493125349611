<template>
  <div>
    <el-form
      size="medium"
      class="medium-form"
      :rules="rules"
      label-width="120px"
      labelPosition="top"
      ref="form"
      @submit.native.prevent="() => {}"
    >
      <el-form-item
        v-for="item in formArray"
        :key="item.props.normal.label"
        :label="`${item.props.normal.label}： `"
        :prop="
          item.props.normal.required ? item.props.normal.required + '' : ''
        "
      >
        <template v-if="['base-select'].includes(item.field)">
          <el-radio-group v-model="item.user_data">
            <el-radio
              disabled
              v-for="option in item.props.normal.options"
              :key="option"
              :label="option"
              >{{ option }}
            </el-radio>
          </el-radio-group>
        </template>
        <template
          v-else-if="
            [
              ,
              'base-input',
              'base-input-number',
              'base-time-picker',
              'phone',
            ].includes(item.field)
          "
        >
          <el-input disabled v-model="item.user_data"></el-input>
        </template>
        <template v-else-if="['base-textarea'].includes(item.field)">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 4 }"
            disabled
            v-model="item.user_data"
          ></el-input>
        </template>
        <template v-else-if="['location'].includes(item.field)">
          <el-input
            disabled
            :value="item.user_data.address + item.user_data.address_name"
          ></el-input>
        </template>
        <template v-else-if="['base-multi-select'].includes(item.field)">
          <el-checkbox-group disabled v-model="item.user_data">
            <el-checkbox
              v-for="option in item.props.normal.options"
              :label="`${option}`"
              :value="option"
              :key="option"
            >
              {{ option }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
        <template v-else-if="['attachment'].includes(item.field)">
          <el-image
            style="width: 118px; height: 118px; border-radius: 4px;margin-right: 10px;"
            v-for="t2 in item.user_data"
            :key="t2.url"
            :src="t2.url"
            :preview-src-list="item.user_data.map((d) => d.url)"
          />
        </template>
        <template v-else-if="['base-file-picker'].includes(item.field)">
          <ol style="padding-inline-start: 20px; margin: 0">
            <li v-for="it in item.user_data" :key="it.url">
              <a
                class="file-link"
                target="_blank"
                :href="it.url"
                :download="it.name"
                >{{ it.name || it.url }}</a
              >
            </li>
          </ol>
        </template>
      </el-form-item>
    </el-form>
    <!-- {{ form }}
    <button @click="getFormData">123</button> -->
  </div>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
export default {
  components: { SingleMediaWall },
  props: ['forms'],
  data() {
    return {
      rules: {
        1: [{ required: true, trigger: 'change' }],
      },
      formArray: [],
    }
  },
  methods: {},
  created() {
    // this.getJoinForm();
    this.formArray = this.forms
  },
}
</script>

<style lang="scss" scoped>
.m-t {
  ::v-deep .el-form-item__content {
    margin-top: 40px;
  }
}
</style>
