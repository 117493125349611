var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"form",staticClass:"medium-form",attrs:{"size":"medium","rules":_vm.rules,"label-width":"120px","labelPosition":"top"},nativeOn:{"submit":function($event){$event.preventDefault();return (function () {}).apply(null, arguments)}}},_vm._l((_vm.formArray),function(item){return _c('el-form-item',{key:item.props.normal.label,attrs:{"label":((item.props.normal.label) + "： "),"prop":item.props.normal.required ? item.props.normal.required + '' : ''}},[(['base-select'].includes(item.field))?[_c('el-radio-group',{model:{value:(item.user_data),callback:function ($$v) {_vm.$set(item, "user_data", $$v)},expression:"item.user_data"}},_vm._l((item.props.normal.options),function(option){return _c('el-radio',{key:option,attrs:{"disabled":"","label":option}},[_vm._v(_vm._s(option)+" ")])}),1)]:(
          [
            ,
            'base-input',
            'base-input-number',
            'base-time-picker',
            'phone' ].includes(item.field)
        )?[_c('el-input',{attrs:{"disabled":""},model:{value:(item.user_data),callback:function ($$v) {_vm.$set(item, "user_data", $$v)},expression:"item.user_data"}})]:(['base-textarea'].includes(item.field))?[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 5, maxRows: 4 },"disabled":""},model:{value:(item.user_data),callback:function ($$v) {_vm.$set(item, "user_data", $$v)},expression:"item.user_data"}})]:(['location'].includes(item.field))?[_c('el-input',{attrs:{"disabled":"","value":item.user_data.address + item.user_data.address_name}})]:(['base-multi-select'].includes(item.field))?[_c('el-checkbox-group',{attrs:{"disabled":""},model:{value:(item.user_data),callback:function ($$v) {_vm.$set(item, "user_data", $$v)},expression:"item.user_data"}},_vm._l((item.props.normal.options),function(option){return _c('el-checkbox',{key:option,attrs:{"label":("" + option),"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),1)]:(['attachment'].includes(item.field))?_vm._l((item.user_data),function(t2){return _c('el-image',{key:t2.url,staticStyle:{"width":"118px","height":"118px","border-radius":"4px","margin-right":"10px"},attrs:{"src":t2.url,"preview-src-list":item.user_data.map(function (d) { return d.url; })}})}):(['base-file-picker'].includes(item.field))?[_c('ol',{staticStyle:{"padding-inline-start":"20px","margin":"0"}},_vm._l((item.user_data),function(it){return _c('li',{key:it.url},[_c('a',{staticClass:"file-link",attrs:{"target":"_blank","href":it.url,"download":it.name}},[_vm._v(_vm._s(it.name || it.url))])])}),0)]:_vm._e()],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }