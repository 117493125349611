<template>
  <div class="body" v-loading="showLoading">
    <div class="top flex">
      <div class="flex flex-align-center" v-if="configForm.user">
        <el-avatar :src="configForm.user.avatar || defaultAvatar" :size="48" />
        <h3 class="flex-1">
          {{ configForm.user.nickname || '--' }}
        </h3>
      </div>

      <div class="tags" :class="[configForm.status]">
        {{ configForm.status_text }}
      </div>

    </div>
    <el-form v-if="!showLoading" ref="detailForm" size="medium" label-position="right" label-width="120px"
      class="form small-form">
      <el-form-item label="使用目的：">
        {{ configForm.purpose || '--' }}
      </el-form-item>
      <el-form-item label="申请人：">
        {{ configForm.user.nickname || '--' }}
      </el-form-item>
      <el-form-item label="申请人联系电话：">
        {{ configForm.user.phone || '--' }}
      </el-form-item>
      <el-form-item label="申请场地：">
        <span style="color: #3576FF;cursor:pointer" @click="toInfo">
          {{ configForm.venue.name || '--' }}
        </span>
      </el-form-item>
      <el-form-item label="使用时间：">
        <div v-for="t, i in configForm.times" :key="i">{{ t }}</div>
      </el-form-item>
      <el-form-item label="使用类型：">
        {{ configForm.type_text || '--' }}
      </el-form-item>
      <el-form-item label="关联活动：">
        <div class="activity flex" style="cursor:pointer" @click="toActivity" v-if="configForm.activity">
          <img class="cover" :src="configForm.activity.cover">
          <div class="info flex">
            <div class="title ">
              <div class="tags">
                {{ configForm.activity.activity_status_text_three }}
              </div>
              {{ configForm.activity.name
              }}
            </div>
            <div class="tiem">
              <img class="icon" src="../assets/images/tiem.png">
              {{ configForm.activity.activity_time_text
              }}
            </div>
          </div>
        </div>
        <template v-else>
          --
        </template>

      </el-form-item>
      <el-form-item label="审批意见：" v-if="$route.name !== 'VenueCheckDetail'">
        <div>
          {{ configForm.check_opinion || '--' }}
        </div>
      </el-form-item>
    </el-form>
    <div v-if="configForm.forms &&configForm.forms.length " class="single-form-item" :data-last="true">
      <label class="config-section-title">申请表单</label>
      <CustomForm  style="margin-top: 20px;" :forms="configForm.forms"></CustomForm>
    </div>

    <FixedActionBar v-loading="showLoading || saveLoading">
      <el-button @click="$router.back()">返回</el-button>
      <template v-if="$route.name === 'VenueCheckDetail'">
        <el-button type="danger" @click="dialogFormVisible = true; textarea = ''; fun = uncheck">驳回</el-button>
        <el-button type="success" @click="dialogFormVisible = true; textarea = ''; fun = check">同意</el-button>
      </template>
    </FixedActionBar>

    <el-dialog title="审批意见" width="500px" :visible.sync="dialogFormVisible">
      <el-input type="textarea" :rows="8" placeholder="请输入" show-word-limit maxlength="250" v-model="textarea">
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="!textarea" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* api */

import {
  getDetail,
  uncheck, check
} from '@/modules/venue/api/check-detail'

/* 工具 */
import FixedActionBar from '@/base/layout/FixedActionBar'
import CustomForm from '@/modules/venue/components/check-detail/CustomForm'
export default {
  components: {
    FixedActionBar,
    CustomForm
  },
  data() {
    return {
      showLoading: true,
      saveLoading: false,
      configForm: {

      },
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
      dialogFormVisible: false,
      textarea: '',
      fun: '',
      uncheck,
      check
    }
  },
  created() {
    this.getDetail()
  },

  methods: {
    getDetail() {
      console.log(this.$route);
      getDetail({ record_id: this.$route.params.id }).then(res => {
        this.showLoading = false
        this.configForm = res.data
      })
    },
    toInfo() {
      this.$router.push({
        name: "EditVenue",
        params: { id: this.configForm.venue.id, step: 0 },
      })
    },
    toActivity() {
      this.$router.push({
        name: "ActivityDetail",
        params: { type: "edit", id: this.configForm.activity.id, create_step: 0 },
      });
    },
    submit() {
      this.saveLoading = true
      this.dialogFormVisible = false
      this.fun({ record_id: this.$route.params.id, check_opinion: this.textarea }).then(res => {
        this.saveLoading = false
        this.$message.success('操作成功')
        this.$router.back()
      }).catch(() => {
        this.saveLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  min-height: 400px;
  padding-top: $space;

  .top {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .flex-1 {
      margin-left: 13px;
      margin-right: 13px;
      @include nowrap();
    }

    .image {
      width: 50px;
      height: 50px;
      border-radius: 999em;
      margin: 20px;
    }
  }

  .activity {
    display: flex;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    padding: 4px;
    width: 380px;

    .cover {
      width: 101px;
      height: 76px;
      min-width: 101px;
      border-radius: 6px;
      margin-right: 8px;
      background-color: rgba(255, 38, 71, 0.1);
    }

    .info {
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0px;

      .title {

        color: rgb(26, 26, 26);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
        display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
        -webkit-line-clamp: 3; //下多行文字溢出点点点...
        -webkit-box-orient: vertical;

        // width: 100%;


        .tags-text {
          display: inline;
          vertical-align: middle;
        }

        .active {
          color: rgb(255, 38, 71);
          background-color: rgba(255, 38, 71, 0.1);
        }

        .progress {
          color: rgba(255, 123, 27, 1);
          background-color: rgba(255, 123, 27, 0.1);
        }
      }

      .tiem {
        display: flex;
        align-items: center;

        .icon {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }

  }

  .tags {
    display: inline-block;
    // width: 72rpx;
    padding: 0 5px;
    height: 20px;
    color: rgba(128, 128, 128, 1);
    font-size: 10px;
    font-weight: 400;
    background-color: rgba(128, 128, 128, 0.1);
    text-align: center;
    line-height: 20px;
    margin-right: 4px;
    border-radius: 6px;
    vertical-align: middle;
  }

  .checking {
    color: rgba(#FF7B1B, 1);
    background-color: rgba(#FF7B1B, 0.1);
  }

  .not_running {
    color: rgba(#F3BA28, 1);
    background-color: rgba(#F3BA28, 0.1);
  }

  .running {

    color: greenyellow;
    background-color: rgba(greenyellow, 0.1);
  }

  .unchecked {
    color: rgba(red, 1);
    background-color: rgba(red, 0.1);
  }

}
</style>

